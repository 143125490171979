import 'simplebar/dist/simplebar.min.css';
import 'shaka-player/dist/controls.css';

import React from 'react';
import { Flex } from 'rebass/styled-components';

import Loadable from '@loadable/component';

import Layout from '../../components/layout';
import Loading from '../../components/Loading';

const EventPage = Loadable(() => import('../../components/EventPage'), {
  fallback: (
    <Flex
      sx={{
        width: '100%',
        height: '100%',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Loading />
    </Flex>
  ),
  ssr: false,
});

const ScreeningEventProvider = Loadable(
  () => import('../../hooks/useScreeningEvent'),
  {
    ssr: false,
  }
);

const EventRouter: React.FC<any> = props => {
  return (
    <Layout dark>
      <ScreeningEventProvider slug={props.params.slug}>
        <EventPage />
      </ScreeningEventProvider>
    </Layout>
  );
};

export default EventRouter;
