import React from 'react';
import styled, { keyframes } from 'styled-components';

const animation = keyframes`
  0% {
    transform: scale(1);
  }
  50%,
    75% {
    transform: scale(2.5);
  }
  78%, 100% {
    opacity: 0;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  width: 120px;
  overflow: hidden;
  animation-delay: 1s;
`;

const Item = styled.div<{ delay: string; color: string }>`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${props =>
    props.color ? props.color : props.theme.colors.yellow};
  margin: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:before {
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: ${props =>
      props.color ? props.color : props.theme.colors.yellow};
    opacity: 0.7;
    animation: ${animation} 2s infinite cubic-bezier(0, 0, 0.49, 1.02);
    animation-delay: ${props => props.delay}ms;
    transition: 0.5s all ease;
    transform: scale(1);
  }
`;

const Loading: React.FC<{ color?: string }> = ({ color }) => {
  return (
    <Container>
      <Item delay="250" color={color}></Item>
      <Item delay="500" color={color}></Item>
      <Item delay="750" color={color}></Item>
      <Item delay="1000" color={color}></Item>
    </Container>
  );
};

export default Loading;
